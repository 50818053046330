<template>
  <v-sheet max-width="350" :color="wsLIGHTCARD" class="pa-3 wsRoundedLight">


    <h5 class="wsDARKLIGHT ">
     {{ $time(item.createdAt).regularTime()  }}
    </h5>

    <!-- TEXT -->
    <h5 class="wsDARKER font-weight-regular"
        v-html="parsedContent">
    </h5>


    <!-- POSTER -->
    <ws-img
        v-if="item.poster && item.poster.type === 'image'"
        :src="item.poster.url"
        class="wsRoundedLight mt-5"

    />



  </v-sheet>
</template>


<script>

export default {
  name: "mobileNewsCard",
  props : {
    item : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      md: null,
    }
  },
  async created() {
    const MarkdownIt = (await import("markdown-it")).default;
    this.md = new MarkdownIt();
  },
  computed: {
    parsedContent() {
      return this.md ? this.md.render(this.item.content || "") : "";
      // return this.item.content.replace(/\n/g, "<br>");
    },
  },
  methods : {
    prepareText(text){

      return text.replace(/\n/g, "<br>");
    }
  }
}
</script>


<style scoped>

</style>